<template>
  <div class="page poem">
    <div class="container">
      <transition name="fade">
        <div class="mark1" v-if="exp" @click="hide"></div>
      </transition>
      <div id="left" :class="{exp:exp}">
        <div class="leftn">
          <div class="tan" @click="exp=!exp"></div>
          <div class="tags">
            <div class="httitle f20">标签</div>
            <div class="tagsList f14">
            <span @click="toggle('chooseTag',item)" v-for="(item,xh) in tags" :key="xh"
                  :class="{cur:chooseAll.chooseTag==item}">{{item}}</span>
            </div>
          </div>
          <div class="type">
            <div class="httitle f20">年级</div>
            <ul class="f16">
              <li @click="toggle('chooseGrade',item.id)" :class="{cur:chooseAll.chooseGrade===item.id}"
                  v-for="(item,xh) in grade" :key="xh">
                {{item.title}}<i v-if="getTotalNum(item.id)>0">{{getTotalNum(item.id)}}</i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="right">
        <div class="position f16">
          <span>{{chooseWhich}}</span>
          <div class="orderBy f14">
            <dl>
              <dt @click="px('love')" :class="{cur:orderBy.curitem==='love'}">喜欢数
                <svg :class="{sup:orderBy.sortby['love']==1,sdown:orderBy.sortby['love']==0}" width="11"
                     height="16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <rect x="4.836" y="4.561" width="1.328" height="6.696" rx="0.2" fill="currentColor"></rect>
                  <path d="M2.795 5.94a.25.25 0 01-.176-.426l2.598-2.6a.4.4 0 01.566 0l2.598 2.6a.25.25 0 01-.176.426h-5.41z"
                        fill="currentColor"></path>
                </svg>
              </dt>
              <dt @click="px('poet')" :class="{cur:orderBy.curitem==='poet'}">作者名称
                <svg :class="{sup:orderBy.sortby['poet']==1,sdown:orderBy.sortby['poet']==0}" width="11"
                     height="16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <rect x="4.836" y="4.561" width="1.328" height="6.696" rx="0.2" fill="currentColor"></rect>
                  <path d="M2.795 5.94a.25.25 0 01-.176-.426l2.598-2.6a.4.4 0 01.566 0l2.598 2.6a.25.25 0 01-.176.426h-5.41z"
                        fill="currentColor"></path>
                </svg>
              </dt>
            </dl>
          </div>
        </div>
        <ul class="list" v-if="poemFilter.length>0">
          <router-link tag="li" :to="'/poem/'+item.id" v-for="(item,xh) in poemFilter" :key="xh">
            <div class="tips">
              <i class="img" v-if="item.img">图</i>
              <i class="level" v-if="!item.grade">外</i>
              <i class="love" v-if="item.love>1">
                <svg t="1707211522108" class="zan" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     p-id="4190" width="32" height="32">
                  <path d="M531.456 880.128c-15.872 15.872-41.984 15.872-57.856 0l-336.384-336.384c-100.864-100.864-100.864-264.704 0-365.568 100.864-100.864 264.704-100.864 365.568 0 100.864-100.864 264.704-100.864 365.568 0 100.864 100.864 100.864 264.704 0 365.568l-336.896 336.384z"
                        p-id="4191"></path>
                </svg>
                {{item.love}}</i>
            </div>
            <div class="poemT f24 ellipsis1">{{item.poem}}</div>
            <div class="poetT f14">{{getDynasty(item.poet)}}·<i>
              <router-link :to="'/poet/'+getPoetId(item.poet)">{{item.poet}}</router-link>
            </i></div>
            <div class="content ellipsis f16" v-html="jie(item.content)"></div>
          </router-link>
        </ul>
        <div class="pages" v-if="poemFilter.length>0">
          <dl class="f16">
            <dt @click="page=xh+1" v-for="(item,xh) in totalpage" :key="xh" :class="{cur:page==xh+1}">{{item}}</dt>
          </dl>
        </div>
        <div v-else class="nodata f24">没有数据</div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
    name: 'poem',
    components: {},
    data() {
        return {
            exp: false,
            poet: [],
            grade: [],
            poem: [],
            poemAll: [],//原始数据
            chooseAll: {
                chooseGrade: '',
                chooseTag: '',
            },
            orderBy: {
                curitem: "",
                sortby: {'poet': 0, 'love': 0}, // 1 升序  0 降序
            },//这个把排序的选项弄到这边，动态watch
            page: 1,
            totalpage: 1,
            pageSize: 20,
        }
    },
    created() {
        let formData1 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'grade',
            perpage: 10000,
        }

        let formData3 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poem',
            perpage: 10000,
            order: ["id DESC"]
        }
        let formData4 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poet',
            perpage: 10000,
            order: ["id DESC"]
        }


        if (this.$store.state.articles.grade.length === 0) {
            this.$store.dispatch('articles/getList', formData1).then((rs) => {
                this.grade = rs
            })
        } else {
            this.grade = this.$store.state.articles.grade
        }


        if (this.$store.state.articles.poem.length === 0) {
            this.$store.dispatch('articles/getList', formData3).then((rs) => {
                this.poem = rs
                this.poemAll = Object.assign([], this.poem)
            })
        } else {
            this.poem = this.$store.state.articles.poem
            this.poemAll = Object.assign([], this.poem)
        }
        if (this.$store.state.articles.poet.length === 0) {
            this.$store.dispatch('articles/getList', formData4).then((rs) => {
                this.poet = rs
            })
        } else {
            this.poet = this.$store.state.articles.poet
        }


    },
    methods: {
        hide() {
            this.exp = false
        },
        toggle(which, text) {
            if (this.chooseAll[which] && this.chooseAll[which] == text) {
                this.chooseAll[which] = ''
            } else {
                let k = Object.keys(this.chooseAll)
                k.forEach((el) => {
                    this.$set(this.chooseAll, el, '')
                    this.chooseAll[which] = text
                })

            }
        },
        px(el) {
            if (el != this.orderBy.curitem) {
                this.orderBy.curitem = el
            } else {
                if (this.orderBy.sortby[this.orderBy.curitem] == 1) {
                    this.orderBy.sortby[this.orderBy.curitem] = 0
                } else {
                    this.orderBy.sortby[this.orderBy.curitem] = 1
                }
            }
        },
        getTotalNum(text) {
            let fi = this.poem.filter((el) => {
                return el.grade == text
            })
            return fi.length
        },
        getGrade(id) {
            let fi = this.grade.filter((el) => {
                return el.id === id
            })
            if (fi.length > 0) {
                return fi[0].title
            }
        },
        getDynasty(text) {
            let fi = this.poet.filter((el) => {
                return el.poet === text
            })
            if (fi.length > 0) {
                return fi[0].dynasty
            }
        },
        getPoetId(text) {
            let fi = this.poet.filter((el) => {
                return el.poet === text
            })
            if (fi.length > 0) {
                return fi[0].id
            } else {
                return ''
            }
        },
        jie(html) {
            //let much
            let reg = new RegExp('<[^>]+>', 'gi');  //过滤所有的html标签，不包括内容
            if (typeof html != 'string') {  //不是字符串
                return html;
            }
            let last = html.replace(reg, '')
            let reg1 = new RegExp('([0-9]|[\u2460-\u249B])+', 'gi')
            //let z = last.match(reg1)
             last=last.replace(reg1,'')

            // if (z) {
            //     for(let i=0;i<z.length;i++){
            //         last=last.replace(z[i],'<sup>'+z[i]+'</sup>')
            //     }
            // }


            if (window.innerWidth > 768) {
                //much = 60
            } else {
                //much = 20
            }

            //let lh = last.substr(0, much)
            return last
        },
    },
    computed: {
        chooseWhich() {
            let k = Object.keys(this.chooseAll)
            let fi = k.filter((el) => {
                return this.chooseAll[el]
            })
            let rs
            if (fi[0] == 'chooseTag') {
                rs = this.chooseAll[fi[0]]
            } else {
                rs = this.getGrade(this.chooseAll[fi[0]])

            }
            return rs
        },
        poemFilter() {
            let lastData = []
            let fi = []
            if (!this.chooseAll.chooseGrade && !this.chooseAll.chooseTag) {
                fi = this.poem
            } else {
                if (this.chooseAll.chooseGrade) {
                    fi = this.poem.filter((el) => {
                        return el.grade == this.chooseAll.chooseGrade
                    })
                } else {
                    fi = this.poem.filter((el) => {
                        return String(el.tags).indexOf(this.chooseAll.chooseTag) > -1
                    })

                }
            }
            this.$set(this, 'totalpage', Math.ceil(fi.length / this.pageSize))
            lastData = fi.filter((el, index) => {
                return index >= (this.page - 1) * this.pageSize && index < this.page * this.pageSize
            })
            return lastData

        },
        tags() {
            let tags = []
            let tag = ''
            this.poemAll.forEach((el) => {
                if (el.tags) {
                    tag += el.tags + '|'
                }
            })
            tags = tag.split('|')
            tags.splice(tags.length - 1, 1)
            //tag出现次数方法
            const obj = tags.reduce((sum, item) => {
                sum[item] = sum[item] ? sum[item] += 1 : 1
                return sum
            }, {})
            let fi = Object.keys(obj).filter((el) => {
                return obj[el] > 1
            })
            return fi
        }
    },
    watch: {
        chooseAll: {
            immediate: true,
            deep: true,
            handler() {
                this.page = 1
            }
        },

        orderBy: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value.sortby[value.curitem] == 1) {
                    this.poem.sort((a, b) => {
                        let value1 = a[value.curitem],
                            value2 = b[value.curitem];
                        if (value.curitem == "poet") {
                            return value1.localeCompare(value2, "zh")
                        } else {
                            return value1 - value2;
                        }
                    });

                } else {
                    this.poem.sort((a, b) => {
                        let value1 = a[value.curitem],
                            value2 = b[value.curitem];
                        if (value.curitem == "poet") {
                            return value2.localeCompare(value1, "zh")
                        } else {
                            return value2 - value1;
                        }

                    });
                }

            }
        },
    },
}
</script>

